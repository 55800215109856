const NotFound = () => import(/* webpackChunkName: "NotFoundPage" */ '@/views/Error/NotFoundPage.vue');

const Login = () => import(/* webpackChunkName: "Login" */ '@/views/Login.vue');

const Invite = () => import(/* webpackChunkName: "Invite" */ '@/views/Invite.vue');
const FunctionBoxActivation = () => import(/* webpackChunkName: "FunctionBoxActivation" */ '@/views/FunctionBoxActivation.vue');
const Activate = () => import(/* webpackChunkName: "Activate" */ '@/views/Activate.vue');
const Activated = () => import(/* webpackChunkName: "Activated" */ '@/views/Activated.vue');

const Messages = () => import(/* webpackChunkName: "Messages" */ '@/views/Messages/Page');
const MessagesSent = () => import(/* webpackChunkName: "MessagesSent" */ '@/views/Messages/Sent');
const MessagesInbox = () => import(/* webpackChunkName: "MessagesInbox" */ '@/views/Messages/Inbox');
const MessagesExternal = () => import(/* webpackChunkName: "MessagesExternal" */ '@/views/Messages/External');
const MessagesNotification = () => import(/* webpackChunkName: "MessagesNotification" */ '@/views/Messages/Notification');
const MessageView = () => import(/* webpackChunkName: "MessageView" */ '@/views/Messages/View');

const Conversation = () => import(/* webpackChunkName: "EmailTemplates" */ '@/views/Conversation');

const EmailTemplates = () => import(/* webpackChunkName: "EmailTemplates" */ '@/views/EmailTemplates');

const RedirectMessageLink = () => import(/* webpackChunkName: "RedirectMessageLink" */ '@/views/RedirectMessageLink');

const Meetings = () => import(/* webpackChunkName: "Meetings" */ '@/views/Meetings/Page');
const MeetingsCalendar = () => import(/* webpackChunkName: "MeetingsSent" */ '@/views/Meetings/Calendar');
const MeetingsDrafts = () => import(/* webpackChunkName: "MeetingsDrafts" */ '@/views/Meetings/Drafts');

const Link = () => import(/* webpackChunkName: "Link" */ '@/views/Link');
const Meeting = () => import(/* webpackChunkName: "Link" */ '@/views/Meeting');
//const SecuredMeeting = () => import(/* webpackChunkName: "Link" */ '@/views/SecuredMeeting');

const Download = () => import(/* webpackChunkName: "Download" */ '@/views/Download');
const CannotDownload = () => import(/* webpackChunkName: "CannotDownload" */ '@/views/Error/CannotDownload');
const CannotDownloadLink = () => import(/* webpackChunkName: "CannotDownload" */ '@/views/Error/CannotDownloadLink');
const ServerNotResponding = () => import(/* webpackChunkName: "ServerNotResponding" */ '@/views/Error/ServerNotResponding');

const Dashboard = () => import(/* webpackChunkName: "Dashboard" */ '@/views/Dashboard');
const Support = () => import(/* webpackChunkName: "Support" */ '@/views/Support');

const Organisation = () => import(/* webpackChunkName: "Organisation" */ '@/views/Organisation/Page');
const OrganisationUsers = () => import(/* webpackChunkName: "OrganisationUsers" */ '@/views/Organisation/Users');
const OrganisationLog = () => import(/* webpackChunkName: "OrganisationLog" */ '@/views/Organisation/Log');
const OrganisationInformation = () => import(/* webpackChunkName: "OrganisationInformation" */ '@/views/Organisation/Information');
const OrganisationStatistics = () => import(/* webpackChunkName: "OrganisationSettingsManage" */ '@/views/Organisation/Statistics');
const OrganisationNodes = () => import(/* webpackChunkName: "OrganisationNodes" */ '@/views/Organisation/Nodes');
const OrganisationSettings = () => import(/* webpackChunkName: "OrganisationSettings" */ '@/views/Organisation/Settings/Page');
const OrganisationSettingsEmail = () => import(/* webpackChunkName: "OrganisationSettingsEmail" */ '@/views/Organisation/Settings/Email');
const OrganisationSettingsManage = () => import(/* webpackChunkName: "OrganisationSettingsManage" */ '@/views/Organisation/Settings/Manage');
const OrganisationSettingsApi = () => import(/* webpackChunkName: "OrganisationSettingsManage" */ '@/views/Organisation/Settings/Api');
const OrganisationSettingsLoa = () => import(/* webpackChunkName: "OrganisationSettingsLoa" */ '@/views/Organisation/Settings/Loa');


const OrganisationSettingsPrice = () => import(/* webpackChunkName: "OrganisationSettingsPrice" */ '@/views/Organisation/Settings/Prices');
const OrganisationInformationFunctionBox = () => import(/* webpackChunkName: "OrganisationInformationFunctionBox" */ '@/views/Organisation/Settings/FunctionBox');

const Administration = () => import(/* webpackChunkName: "Administration" */ '@/views/Administration/Page');
const AdministrationOrganisation = () => import(/* webpackChunkName: "AdministrationOrganisation" */ '@/views/Administration/Organisations/Page');
const AdministrationOrganisationsList = () => import(/* webpackChunkName: "AdministrationOrganisationsList" */ '@/views/Administration/Organisations/List');

const AdministrationNodes = () => import(/* webpackChunkName: "AdministrationNodes" */ '@/views/Administration/Nodes');
const AdministrationStart = () => import(/* webpackChunkName: "AdministrationStart" */ '@/views/Administration/Start');
const AdministrationLog = () => import(/* webpackChunkName: "AdministrationLog" */ '@/views/Administration/Log');

const AdministrationAuthentications = () => import(/* webpackChunkName: "AdministrationSettings" */ '@/views/Administration/Authentications/Page');
const AdministrationAuthenticationsStandard = () => import(/* webpackChunkName: "AdministrationSettingsAuthentications" */ '@/views/Administration/Authentications/Standard');
const AdministrationAuthenticationsCustom = () => import(/* webpackChunkName: "AdministrationSettingsAuthentications" */ '@/views/Administration/Authentications/Custom');
const AdministrationAuthenticationsLog = () => import(/* webpackChunkName: "AdministrationLog" */ '@/views/Administration/Authentications/Log');


const AdministrationBilling = () => import(/* webpackChunkName: "AdministrationBilling" */ '@/views/Administration/Billing/Page');
const AdministrationBillingReports = () => import(/* webpackChunkName: "AdministrationBillingReports" */ '@/views/Administration/Billing/Reports');
const AdministrationBillingNode = () => import(/* webpackChunkName: "AdministrationBillingNode" */ '@/views/Administration/Billing/Node');
const AdministrationBillingService = () => import(/* webpackChunkName: "AdministrationBillingService" */ '@/views/Administration/Billing/Service');
const AdministrationBillingPartner = () => import(/* webpackChunkName: "AdministrationBillingPartner" */ '@/views/Administration/Billing/Partner');

const FileStorage = () => import(/* webpackChunkName: "FileStorage" */ '@/views/FileStorage/Page');
const FileStorageList = () => import(/* webpackChunkName: "FileStorage" */ '@/views/FileStorage/List');

const routes = [
  {
    path: "/",
    redirect: { name: 'Login' },
    name: " ",
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    meta: {
      requiresAuth: true,
      showVersion: true,
      showHeader: true,
      showFullscreen: false,
      showFooter: true
    }
  },
  {
    path: "/support",
    name: "Support",
    component: Support,
    meta: {
      requiresAuth: true,
      showVersion: true,
      showHeader: true,
      showFullscreen: false,
      showFooter: true
    }
  },
  {
    name: "Organisation",
    path: "/organisation/:organisation_id",
    component: Organisation,
    redirect: { name: 'Organisation.Users' },
    props: route => ({ organisation_id: route.params.organisation_id }),
    meta: {
      breadCrumb: "ORGANISATION.TITLE"
    },    
    children: [
      {
        path: "users",
        name: "Organisation.Users",
        component: OrganisationUsers,
        meta: {
          requiresAuth: true,
          showVersion: true,
          showHeader: true,
          showFullscreen: false,
          showFooter: true,
          breadCrumb: "USERS"
        }
      },
      {
        path: "log",
        name: "Organisation.Log",
        component: OrganisationLog,
        meta: {
          requiresAuth: true,
          showVersion: true,
          showHeader: true,
          showFullscreen: false,
          showFooter: true,
          breadCrumb: "LOGG"
        }
      },
      {
        path: "information",
        name: "Organisation.Information",
        component: OrganisationInformation,
        meta: {
          requiresAuth: true,
          showVersion: true,
          showHeader: true,
          showFullscreen: false,
          showFooter: true,
          breadCrumb: "INFORMATION"
        }
      },
      {
        path: "statistics",
        name: "Organisation.Statistics",
        component: OrganisationStatistics,
        meta: {
          requiresAuth: true,
          showVersion: true,
          showHeader: true,
          showFullscreen: false,
          showFooter: true,
          breadCrumb: "STATISTICS.TITLE"
        }
      },
      {
        path: "settings",
        name: "Organisation.Settings",
        component: OrganisationSettings,
        redirect: { name: 'Organisation.Settings.Email' },
        meta: {
          breadCrumb: "SETTINGS"
        },
        children: [
          {
            path: "functionbox",
            name: "Organisation.Settings.FunctionBox",
            component: OrganisationInformationFunctionBox,
            meta: {
              requiresAuth: true,
              showVersion: true,
              showHeader: true,
              showFullscreen: false,
              showFooter: true,
              breadCrumb: "FUNCTIONBOX.TITLE"
            }
          },
          {
            path: "api",
            name: "Organisation.Settings.Api",
            component: OrganisationSettingsApi,
            meta: {
              requiresAuth: true,
              showVersion: true,
              showHeader: true,
              showFullscreen: false,
              showFooter: true,
              breadCrumb: "API"
            }
          },
          {
            path: "loa",
            name: "Organisation.Settings.Loa",
            component: OrganisationSettingsLoa,
            meta: {
              requiresAuth: true,
              showVersion: true,
              showHeader: true,
              showFullscreen: false,
              showFooter: true,
              breadCrumb: "LOA.TITLE"
            }
          },
          {
            path: "email",
            name: "Organisation.Settings.Email",
            component: OrganisationSettingsEmail,
            meta: {
              requiresAuth: true,
              showVersion: true,
              showHeader: true,
              showFullscreen: false,
              showFooter: true,
              breadCrumb: "EMAIL_OUTGOING"
            }
          },
          {
            path: "manage",
            name: "Organisation.Settings.Manage",
            component: OrganisationSettingsManage,
            meta: {
              requiresAuth: true,
              showVersion: true,
              showHeader: true,
              showFullscreen: false,
              showFooter: true,
              breadCrumb: "MANAGE"
            }
          },
          {
            path: "price",
            name: "Organisation.Settings.Prices",
            component: OrganisationSettingsPrice,
            meta: {
              requiresAuth: true,
              showVersion: true,
              showHeader: true,
              showFullscreen: false,
              showFooter: true
            }
          }
        ]
      },
      {
        path: "nodes",
        name: "Organisation.Nodes",
        component: OrganisationNodes,
        meta: {
          requiresAuth: true,
          showVersion: true,
          showHeader: true,
          showFullscreen: false,
          showFooter: true,
          breadCrumb: "NODES.TITLE"
        }
      }
    ]
  },
  {
    name: "Administration",
    path: "/administration",
    component: Administration,
    redirect: { name: 'Administration.Organisations' },
    meta: {
      breadCrumb: "ADMINISTRATION.TITLE"
    },
    children: [
      {
        path: "start",
        name: "Administration.Start",
        component: AdministrationStart,
        meta: {
          requiresAuth: true,
          showVersion: true,
          showHeader: true,
          showFullscreen: false,
          showFooter: true
        }
      },
      {
        path: "organisations",
        name: "Administration.Organisations",
        component: AdministrationOrganisation,
        redirect: { name: 'Administration.Organisations.List' },  
        meta: {
          breadCrumb: "ORGANISATIONS"
        },
        children: [
          {
            path: "list",
            name: "Administration.Organisations.List",
            component: AdministrationOrganisationsList,
            meta: {
              requiresAuth: true,
              showVersion: true,
              showHeader: true,
              showFullscreen: false,
              showFooter: true,
              breadCrumb: "LIST"
            }
          },
          {
            name: "Administration.Organisation",
            path: "organisation/:organisation_id",
            component: Organisation,
            redirect: { name: 'Administration.Organisation.Users' },
            props: route => ({ organisation_id: route.params.organisation_id }),
            meta: {
              breadCrumb: "ORGANISATION.TITLE"
            },
            children: [
              {
                path: "log",
                name: "Administration.Organisation.Log",
                component: OrganisationLog,
                meta: {
                  requiresAuth: true,
                  showVersion: true,
                  showHeader: true,
                  showFullscreen: false,
                  showFooter: true,
                  breadCrumb: "LOGG"
                }
              },
              {
                path: "users",
                name: "Administration.Organisation.Users",
                component: OrganisationUsers,
                meta: {
                  requiresAuth: true,
                  showVersion: true,
                  showHeader: true,
                  showFullscreen: false,
                  showFooter: true,
                  breadCrumb: "USERS"
                }
              },
              {
                path: "information",
                name: "Administration.Organisation.Information",
                component: OrganisationInformation,
                meta: {
                  requiresAuth: true,
                  showVersion: true,
                  showHeader: true,
                  showFullscreen: false,
                  showFooter: true
                }
              },
              {
                path: "statistics",
                name: "Administration.Organisation.Statistics",
                component: OrganisationStatistics,
                meta: {
                  requiresAuth: true,
                  showVersion: true,
                  showHeader: true,
                  showFullscreen: false,
                  showFooter: true
                }
              },
              {
                path: "settings",
                name: "Administration.Organisation.Settings",
                component: OrganisationSettings,
                redirect: { name: 'Administration.Organisation.Settings.Email' },
                meta: {
                  breadCrumb: "SETTINGS"
                },
                children: [
                  {
                    path: "email",
                    name: "Administration.Organisation.Settings.Email",
                    component: OrganisationSettingsEmail,
                    meta: {
                      requiresAuth: true,
                      showVersion: true,
                      showHeader: true,
                      showFullscreen: false,
                      showFooter: true,
                      breadCrumb: "EMAIL_OUTGOING"
                    }
                  },
                  {
                    path: "manage",
                    name: "Administration.Organisation.Settings.Manage",
                    component: OrganisationSettingsManage,
                    meta: {
                      requiresAuth: true,
                      showVersion: true,
                      showHeader: true,
                      showFullscreen: false,
                      showFooter: true,
                      breadCrumb: "MANAGE"
                    }
                  },
                  {
                    path: "price",
                    name: "Administration.Organisation.Settings.Prices",
                    component: OrganisationSettingsPrice,
                    meta: {
                      requiresAuth: true,
                      showVersion: true,
                      showHeader: true,
                      showFullscreen: false,
                      showFooter: true,
                      breadCrumb: "PRICE"
                    }
                  },
                  {
                    path: "functionbox",
                    name: "Administration.Organisation.Settings.FunctionBox",
                    component: OrganisationInformationFunctionBox,
                    meta: {
                      requiresAuth: true,
                      showVersion: true,
                      showHeader: true,
                      showFullscreen: false,
                      showFooter: true,
                      breadCrumb: "FUNCTIONBOX.TITLE"
                    }
                  },
                  {
                    path: "api",
                    name: "Administration.Organisation.Settings.Api",
                    component: OrganisationSettingsApi,
                    meta: {
                      requiresAuth: true,
                      showVersion: true,
                      showHeader: true,
                      showFullscreen: false,
                      showFooter: true,
                      breadCrumb: "API"
                    }
                  },
                  {
                    path: "loa",
                    name: "Administration.Organisation.Settings.Loa",
                    component: OrganisationSettingsLoa,
                    meta: {
                      requiresAuth: true,
                      showVersion: true,
                      showHeader: true,
                      showFullscreen: false,
                      showFooter: true,
                      breadCrumb: "LOA.TITLE"
                    }
                  }
                ]
              },
              {
                path: "nodes",
                name: "Administration.Organisation.Nodes",
                component: OrganisationNodes,
                meta: {
                  requiresAuth: true,
                  showVersion: true,
                  showHeader: true,
                  showFullscreen: false,
                  showFooter: true,
                  breadCrumb: "NODES"
                }
              }
            ]
          }
        ]
      },
      {
        path: "authentications",
        name: "Administration.Authentications",
        component: AdministrationAuthentications,
        redirect: { name: 'Administration.Authentications.Standard' },
        meta: {
          breadCrumb: "AUTH_METHODS.TITLE"
        },
        children: [
          {
            path: "list",
            name: "Administration.Authentications.Standard",
            component: AdministrationAuthenticationsStandard,
            meta: {
              requiresAuth: true,
              showVersion: true,
              showHeader: true,
              showFullscreen: false,
              showFooter: true,
              breadCrumb: "AUTH_METHODS.STANDARD_METHODS"
            }
          },
          {
            path: "custom",
            name: "Administration.Authentications.Custom",
            component: AdministrationAuthenticationsCustom,
            meta: {
              requiresAuth: true,
              showVersion: true,
              showHeader: true,
              showFullscreen: false,
              showFooter: true,
              breadCrumb: "AUTH_METHODS.CUSTOM_METHODS"
            }
          },
          {
            path: "log",
            name: "Administration.Authentications.Log",
            component: AdministrationAuthenticationsLog,
            meta: {
              requiresAuth: true,
              showVersion: true,
              showHeader: true,
              showFullscreen: false,
              showFooter: true,
              breadCrumb: "LOG"
            }
          }
        ]
      },
      {
        path: "log",
        name: "Administration.Log",
        component: AdministrationLog,
        meta: {
          requiresAuth: true,
          showVersion: true,
          showHeader: true,
          showFullscreen: false,
          showFooter: true,
          breadCrumb: "LOGG"
        }
      },
      {
        path: "nodes",
        name: "Administration.Nodes",
        component: AdministrationNodes,
        meta: {
          requiresAuth: true,
          showVersion: true,
          showHeader: true,
          showFullscreen: false,
          showFooter: true,
          breadCrumb: "NODES"
        }
      },
      {
        path: "billing",
        name: "Administration.Billing",
        component: AdministrationBilling,
        redirect: { name: 'Administration.Billing.Reports' },
        meta: {
          breadCrumb: "BILLING.TITLE"
        },      
        children: [ 
          {
            path: "node",
            name: "Administration.Billing.Node",
            component: AdministrationBillingNode,
            meta: {
              requiresAuth: true,
              showVersion: true,
              showHeader: true,
              showFullscreen: false,
              showFooter: true,
              breadCrumb: "BILLING.NODE"
            }
          },
          {
            path: "service",
            name: "Administration.Billing.Service",
            component: AdministrationBillingService,
            meta: {
              requiresAuth: true,
              showVersion: true,
              showHeader: true,
              showFullscreen: false,
              showFooter: true,
              breadCrumb: "BILLING.SERVICE"
            }
          },
          {
            path: "partner",
            name: "Administration.Billing.Partner",
            component: AdministrationBillingPartner,
            meta: {
              requiresAuth: true,
              showVersion: true,
              showHeader: true,
              showFullscreen: false,
              showFooter: true,
              breadCrumb: "BILLING.PARTNER"
            }
          },
          {
            path: "reports",
            name: "Administration.Billing.Reports",
            component: AdministrationBillingReports,
            meta: {
              requiresAuth: true,
              showVersion: true,
              showHeader: true,
              showFullscreen: false,
              showFooter: true,
              breadCrumb: "BILLING.REPORTS"
            }
          }
        ]
      }
    ]
  },
  {
    name: "Messages",
    path: "/messages",
    component: Messages,
    redirect: { name: 'Messages.Inbox' },
    meta: {
      breadCrumb: "MESSAGES.TITLE"
    },
    props: route => ({ email: route.params.email, functionbox_uuid: route.query.functionbox_uuid || '',  user_uuid: route.query.user_uuid || '', view_message_uuid: route.params.view_message_uuid || '' }),
    children: [
      {
        path: "inbox",
        name: "Messages.Inbox",
        component: MessagesInbox,
        meta: {
          requiresAuth: true,
          showVersion: true,
          showHeader: true,
          showFullscreen: false,
          showFooter: true,
          breadCrumb: "MESSAGES.INBOX",
          crumbParam: 'email'
        }
      },      
      {
        path: "draft",
        name: "Messages.Drafts",
        component: MessagesSent,
        props: route => ({ drafts: route.params.drafts = "true" }),
        meta: {
          requiresAuth: true,
          showVersion: true,
          showHeader: true,
          showFullscreen: false,
          showFooter: true,
          breadCrumb: "MESSAGES.DRAFTS",
          crumbParam: 'email'
        },
      },      
      {
        path: "sent",
        name: "Messages.Sent",
        props: route => ({ drafts: route.params.drafts = "false" }),
        component: MessagesSent,
        meta: {
          requiresAuth: true,
          showVersion: true,
          showHeader: true,
          showFullscreen: false,
          showFooter: true,
          breadCrumb: "MESSAGES.SENT",
          crumbParam: 'email'
        }
      },     
      {
        path: "external",
        name: "Messages.External",
        component: MessagesExternal,
        meta: {
          requiresAuth: true,
          showVersion: true,
          showHeader: true,
          showFullscreen: false,
          showFooter: true,
          breadCrumb: "MESSAGES.EXTERNAL",
          crumbParam: 'email'
        }
      },
      {
        path: "notification",
        name: "Messages.Notification",
        component: MessagesNotification,
        meta: {
          requiresAuth: true,
          showVersion: true,
          showHeader: true,
          showFullscreen: false,
          showFooter: true,
          breadCrumb: "NOTIFICATIONS.TITLE",
          crumbParam: 'email'
        }
      }
    ]
  },
  {
    name: "Meetings",
    path: "/meetings",
    component: Meetings,
    redirect: { name: 'Meetings.Calendar' },
    props: route => ({ email: route.params.email, functionbox_uuid: route.query.functionbox_uuid || '', view_message_uuid: route.params.view_message_uuid || '' }),
    meta: {
      breadCrumb: "MEETINGS.TITLE"
    },
    children: [ 
      {
        path: "calendar",
        name: "Meetings.Calendar",
        component: MeetingsCalendar,
        meta: {
          requiresAuth: true,
          showVersion: true,
          showHeader: true,
          showFullscreen: false,
          showFooter: true,
          breadCrumb: "MEETINGS.CALENDAR"
        }
      },
      {
        path: "drafts",
        name: "Meetings.Drafts",
        component: MeetingsDrafts,
        meta: {
          requiresAuth: true,
          showVersion: true,
          showHeader: true,
          showFullscreen: false,
          showFooter: true,
          breadCrumb: "MESSAGES.DRAFTS"
        }
      }
    ]
  },
  {
    name: "FileStorage",
    path: "/filestorage",
    component: FileStorage,
    props: route => ({ section: route.params.section }),
    redirect: { name: 'FileStorage.Files' },
    meta: {
      requiresAuth: true,
      showVersion: true,
      showHeader: true,
      showFullscreen: false,
      showFooter: true,
      breadCrumb: "FILESTORAGE.TITLE"
    },
    children: [ 
      {
        path: "list/:folder_uuid?",
        name: "FileStorage.Files",
        component: FileStorageList,
        props: route => ({ folder_uuid: route.params.folder_uuid || "", section: "FILES" }),
        meta: {
          requiresAuth: true,
          showVersion: true,
          showHeader: true,
          showFullscreen: false,
          showFooter: true,
          breadCrumb: "FILESTORAGE.LIST"
        }
      },
      {
        path: "application/:folder_uuid?",
        name: "FileStorage.Application",
        component: FileStorageList,
        props: route => ({ folder_uuid: route.params.folder_uuid || "", section: "APPLICATION" }),
        meta: {
          requiresAuth: true,
          showVersion: true,
          showHeader: true,
          showFullscreen: false,
          showFooter: true,
          breadCrumb: "FILESTORAGE.LIST"
        }
      }
    ]
  },
  {
    path: "/login/status/:auth_uuid",
    name: "LoginStatus",
    component: Login,
    meta: {
      requiresAuth: false,
      showVersion: true,
      showHeader: true,
      showFullscreen: false,
      showFooter: true
    },
    props: route => ({ auth_uuid: route.params.auth_uuid })
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      requiresAuth: false,
      showVersion: true,
      showHeader: true,
      showFullscreen: false,
      showFooter: true
    },
    props: route => ({ login: route.query.login, client: route.query.client, auth_uuid: route.query.auth_uui })
  },
  {
    path: "/invite/:code",
    name: "Invite",
    component: Invite,
    meta: {
      requiresAuth: false,
      showVersion: true,
      showHeader: true,
      showFullscreen: false,
      showFooter: true
    },
    props: route => ({ code: route.params.code })
  },
  {
    path: "/invite/:code/status/:auth_uuid",
    name: "InviteStatus",
    component: Invite,
    meta: {
      requiresAuth: false,
      showVersion: true,
      showHeader: true,
      showFullscreen: false,
      showFooter: true
    },
    props: route => ({ code: route.params.code,  auth_uuid: route.params.auth_uuid })
  },
  {
    path: "/functionbox/activate/:code",
    name: "FunctionBoxActivation",
    component: FunctionBoxActivation,
    meta: {
      requiresAuth: false,
      showVersion: true,
      showHeader: true,
      showFullscreen: false,
      showFooter: true
    },
    props: route => ({ code: route.params.code })
  },
  {
    path: "/activate",
    name: "Activate",
    component: Activate,
    meta: {
      requiresAuth: false,
      showVersion: false,
      showHeader: true,
      showFullscreen: false,
      showFooter: true
    }
  },
  {
    path: "/activated",
    name: "Activated",
    component: Activated,
    meta: {
      requiresAuth: false,
      showVersion: false,
      showHeader: true,
      showFullscreen: false,
      showFooter: true
    }
  },
  {
    path: "/email-templates",
    name: "EmailTemplates",
    component: EmailTemplates,
    meta: {
      requiresAuth: false,
      showVersion: false,
      showHeader: false,
      showFullscreen: true,
      showFooter: true
    }
  },
  {
    path: "/download/:eventid",
    name: "Download",
    component: Download,
    meta: {
      requiresAuth: false,
      showVersion: true,
      showHeader: true,
      showFullscreen: false,
      showFooter: true
    },
    props: route => ({ eventid: route.params.eventid })
  },
  {
    path: "/cannot-download",
    name: "CannotDownload",
    component: CannotDownload,
    meta: {
      requiresAuth: false,
      showVersion: true,
      showHeader: true,
      showFullscreen: false,
      showFooter: true
    }
  },
  {
    path: "/message/link/cannot-download",
    name: "CannotDownloadLink",
    component: CannotDownloadLink,
    meta: {
      requiresAuth: false,
      showVersion: false,
      showHeader: false,
      showFullscreen: true,
      showFooter: true
    }
  },
  {
    path: "/server-not-responding",
    name: "ServerNotResponding",
    component: ServerNotResponding,
    meta: {
      requiresAuth: false,
      showVersion: false,
      showHeader: true,
      showFullscreen: false,
      showFooter: true
    }
  },
  {
    path: "/open-message/:message_uuid/:user_uuid",
    name: "RedirectMessageLink",
    component: RedirectMessageLink,
    props: route => ({ message_uuid: route.params.message_uuid, user_uuid: route.params.user_uuid }),
    meta: {
      requiresAuth: false,
      showVersion: true,
      showHeader: true,
      showFullscreen: false,
      showFooter: true
    }
  },
  {
    path: "/message/:message_uuid/f/:functionbox_uuid",
    name: "MessageViewWithFunctionBoxUuid2",
    component: MessageView,
    props: route => ({ inbox: false, message_uuid: route.params.message_uuid, functionbox_uuid: route.params.functionbox_uuid || '', user_uuid: '' }),
    meta: {
      requiresAuth: true,
      showVersion: true,
      showHeader: true,
      showFullscreen: false,
      showFooter: true,
      breadCrumb: "MESSAGES.TITLE",
      crumbParam: 'email'
    }
  },
  {
    path: "/message/:message_uuid/u/:user_uuid",
    name: "MessageViewWithUserUuid",
    component: MessageView,
    props: route => ({ inbox: false, message_uuid: route.params.message_uuid, user_uuid: route.params.user_uuid || '', functionbox_uuid: '' }),
    meta: {
      requiresAuth: true,
      showVersion: true,
      showHeader: true,
      showFullscreen: false,
      showFooter: true,
      breadCrumb: "MESSAGES.TITLE",
      crumbParam: 'email'
    }
  },
  {
    path: "/message/:message_uuid/:functionbox_uuid",
    name: "MessageViewWithFunctionBoxUuid1",
    component: MessageView,
    props: route => ({ inbox: false, message_uuid: route.params.message_uuid, functionbox_uuid: route.params.functionbox_uuid || '' }),
    meta: {
      requiresAuth: true,
      showVersion: true,
      showHeader: true,
      showFullscreen: false,
      showFooter: true,
      breadCrumb: "MESSAGES.TITLE",
      crumbParam: 'email'
    }
  },
  {
    path: "/message/:message_uuid",
    name: "MessageView",
    component: MessageView,
    props: route => ({ inbox: false, message_uuid: route.params.message_uuid, functionbox_uuid: '', user_uuid: '', startDate: route.query.startdate , endDate: route.query.enddate }),
    meta: {
      requiresAuth: true,
      showVersion: true,
      showHeader: true,
      showFullscreen: false,
      showFooter: true,
      breadCrumb: "MESSAGES.TITLE",
      crumbParam: 'email'
    }
  },
  { path: "/meeting/:message_uuid", redirect: '/message/:message_uuid' },
  { path: '/view/:link_uuid', redirect: '/link/view/:link_uuid' },
  {
    path: "/link/view/:link_uuid",
    name: "Link",
    alias: '/view/:link_uuid',
    component: Link,
    meta: {
      requiresAuth: false,
      showVersion: true,
      showHeader: false,
      showFullscreen: false,
      showFooter: true
    },
    props: route => ({ link_uuid: route.params.link_uuid }),
  },
  {
    path: "/meeting/view/:message_uuid",
    name: "MeetingView",
    component: Meeting,
    meta: {
      requiresAuth: false,
      showVersion: true,
      showHeader: false,
      showFullscreen: false,
      showFooter: true
    },
    props: route => ({ message_uuid: route.params.message_uuid }),
  },
  {
    path: "/linkredirect/view/:message_uuid",
    name: "MeetingView2",
    component: Meeting,
    meta: {
      requiresAuth: false,
      showVersion: true,
      showHeader: false,
      showFullscreen: false,
      showFooter: true
    },
    props: route => ({ message_uuid: route.params.message_uuid }),
  },
  {
    path: "/secured-meeting/:meeting_uuid",
    name: "SecuredMeeting",
    component: Meeting,
    meta: {
      requiresAuth: false,
      showVersion: true,
      showHeader: false,
      showFullscreen: true,
      showFooter: false
    },
    props: route => ({ meeting_uuid: route.params.meeting_uuid })
  },
  {
    path: "/link/view/:link_uuid/status/:auth_uuid",
    name: "LinkStatus",
    component: Link,
    meta: {
      requiresAuth: false,
      showVersion: true,
      showHeader: true,
      showFooter: true
    },
    props: route => ({ link_uuid: route.params.link_uuid, auth_uuid: route.params.auth_uuid })
  },
  {
    path: "/conversation",
    name: "Conversation",
    component: Conversation,
    meta: {
      requiresAuth: true,
      showVersion: true,
      showHeader: true,
      showFullscreen: false,
      showFooter: false
    },
    props: route => ({  inbox: false, message_uuid: route.query.message_uuid || '', user_uuid: route.query.user_uuid || '', functionbox_uuid: route.query.functionbox_uuid || '' })
  },
  {
    path: "*",
    name: "NotFound",
    component: NotFound,
    meta: {
      requiresAuth: false,
      showFullscreen: false,
      showVersion: true,
      showHeader: true,
      showFooter: true
    }
  }
];

export default routes;